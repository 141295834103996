<template>
  <q-page class="create-ticket">
    <div class="title">
      Nouveau ticket
    </div>

    <div class="content-container">
        <TicketCreateEditForm
          class="ticket-form"
          :ticketPosition="ticketPosition"
          :message="message"
          :title="title"
          :publicView="true"
          @exitEdition="$emit('exitEdition')"
        />
    </div>
  </q-page>
</template>

<script>
import TicketCreateEditForm from '@/app/pages/ticket/components/ticket-create-edit-form.vue';

export default {
  name: 'TicketCreationEdition',
  components: {
    TicketCreateEditForm,
  },
  props: {
    ticketPosition: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  async created() {
    await this.$store.dispatch('tickets/fetchPublic');
  }
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.title
  font-weight 400
  margin $space-2
  width 100%
  font-size $fs-h1

</style>
